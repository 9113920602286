import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import { Maybe, ContentfulContact } from "types/graphql-types";
import ContactLink from "../components/ContactLink";
import { Mail, Phone, Print } from "@material-ui/icons";

type ContactDetails = Maybe<
  Pick<
    ContentfulContact,
    | "title"
    | "faxLabel"
    | "mailAdress"
    | "subtitleFirstLine"
    | "subtitleSecondLine"
    | "telephoneLabel"
    | "telephoneNumber"
    | "website"
  >
>;

const ContactArea = ({
  contact,
  absolute,
  fullWidth,
}: {
  contact: ContactDetails;
  absolute?: boolean;
  fullWidth?: boolean;
}) => {
  if (!contact) {
    return <div />;
  }

  return (
    <Wrapper absolute={absolute} fullWidth={fullWidth}>
      <Grid justify="space-between" container alignItems="center">
        <Grid item xs={12} sm={4} md={5}>
          <Typography variant="h5">{contact.title}</Typography>
          <Typography variant="body1">{contact.subtitleFirstLine}</Typography>
          <Typography variant="body1">{contact.subtitleSecondLine}</Typography>
          <LinkWrapper>
            <Link href={contact!.website!} target="_blank">
              {contact.website}
            </Link>
          </LinkWrapper>
        </Grid>
        <ContactWrapper item xs={12} sm={7} md={6}>
          <div style={{ float: absolute ? "none" : "right" }}>
            {/* TODO: add missing icons */}
            <ContactLink
              href={`tel:${contact.telephoneNumber}`}
              text={contact!.telephoneLabel!}
              icon={<Phone style={{ fontSize: 16 }} />}
            />
            <FaxLink>
              <Print style={{ fontSize: 16 }} /> <p>{contact!.faxLabel!}</p>
            </FaxLink>

            <ContactLink
              href={`mailto:${contact.mailAdress}`}
              text={contact!.mailAdress!}
              icon={<Mail style={{ fontSize: 16 }} />}
            />
          </div>
        </ContactWrapper>
      </Grid>
    </Wrapper>
  );
};

export default ContactArea;

interface StyleProps {
  absolute?: boolean;
  fullWidth?: boolean;
}

const Wrapper = styled.div<StyleProps>(
  ({ theme, absolute, fullWidth }) => `
    background-color: ${theme.palette.sage.main};
    padding: ${theme.spacing(5, 3, 4)};
    width: 100%;
    margin-top: -${theme.spacing(5)}px;
    z-index: 20;
    h5 {
        text-transform: none;
    }
    p {
      margin-top: 0px !important;
    }
    ${theme.breakpoints.up("sm")} {
        margin-top: -${theme.spacing(5)}px;
        position: ${absolute ? "absolute" : "relative"};
        right: 0;
        top: 0;
        max-width: 100%;
    }
    ${theme.breakpoints.up("md")} {
        position: ${absolute ? "absolute" : "relative"};
        right: 0;
        top: ${absolute ? "-220px" : 0};
        max-width: ${fullWidth ? "100%" : "728px"};
        padding: ${theme.spacing(5, 7, 4)};
    }
    ${theme.breakpoints.up("lg")} {
      padding: ${theme.spacing(7, 7, 5)};
        max-width: ${fullWidth ? "100%" : "928px"};
        right:-${theme.spacing(7)}px;
    }

`
);

const Link = styled.a(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-weight: 700;
    margin-top: ${theme.spacing(2)}px;
`
);

const ContactWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(5)}px;
    ${theme.breakpoints.up("sm")} {
        margin-top: 0;
    }
`
);

const FaxLink = styled.div(
  ({ theme }) => `
  background-color: ${theme.palette.primary.contrastText};
  font-size: 16px;
  line-height: 2;
  margin-bottom: ${theme.spacing(1)}px;
  padding: ${theme.spacing(1, 2)};
  max-width: 435px;
  display: flex;
  align-items: center;
  p {
      margin-left: ${theme.spacing(2)}px;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 0;
  }
`
);

const LinkWrapper = styled.div(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)}px;
`
);
