import React from "react";
import { graphql } from "gatsby";
import Layout from "src/common/Layout";
import SEO from "src/common/components/seo";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import {
  StaticPageQuery,
  ContentfulStaticContentBlock,
  ContentfulContact,
} from "types/graphql-types";
import "react-json-pretty/themes/1337.css";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import ContentfulRichText from "src/common/components/ContentfulRichText";
import ContactArea from "src/common/contact/ContactArea";

const IndexPage = ({
  data: { contentfulStaticPage, contentfulHeader, contentfulFooter },
}: {
  data: StaticPageQuery;
}) => {
  if (!contentfulStaticPage || !contentfulHeader || !contentfulFooter) {
    throw new Error("Not all Contentful requirements met: contentfulNews, contentfulHeader, contentfulFooter");
  }

  const { pageConfig, contentList } = contentfulStaticPage;
  let textJSON;
  if (contentList!.length > 1) {
    textJSON = (contentList![1] as ContentfulStaticContentBlock).richtext;
  } else {
    textJSON = (contentList![0] as ContentfulStaticContentBlock).richtext;
  }
  return (
    <Layout
      contentfulHeader={contentfulHeader}
      contentfulFooter={contentfulFooter}
    >
      <SEO
        title={pageConfig!.seoConfig!.title!}
        description={pageConfig!.seoConfig!.description}
      />
      <ContentWidthWrapper
        centerSelf
        container
        justify="space-between"
        width={877}
      >
        <Wrapper>
          <Headline variant="h1">{pageConfig!.title!.title!}</Headline>
          {contentList!.length > 1 && (
            <div style={{ paddingTop: 32, marginLeft: -56 }}>
              <ContactArea
                contact={contentList![0] as ContentfulContact}
                fullWidth={true}
              />
            </div>
          )}
          <ContentfulRichText json={textJSON?.json} />
        </Wrapper>
      </ContentWidthWrapper>
    </Layout>
  );
};

export const query = graphql`
  query StaticPage($slug: String!, $locale:String!) {
    contentfulStaticPage(
      slug: { eq: $slug },
      node_locale:{eq:$locale}
      ) {
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        seoConfig {
          title
          description
        }
        headerImage {
          file {
            url
          }
        }
      }
      contentList {
        ... on ContentfulContact {
          id
          subtitleFirstLine
          subtitleSecondLine
          title
          telephoneNumber
          telephoneLabel
          website
          mailAdress
          faxLabel
        }
        ... on ContentfulStaticContentBlock {
          richtext {
            json
          }
        }
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

export default IndexPage;

const Wrapper = styled("div")(
  ({ theme }) => `
    padding: ${theme.spacing(22, 0, 11)};
    hr {
      margin: ${theme.spacing(4, 0)};
    }
    h2, h3, h4 {
      margin: ${theme.spacing(8, 0, 4)};
    }
    p {
      margin-top: 25px;
    }
    li {
      p { margin-top: 0;}
    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(5)}px;
`
);
